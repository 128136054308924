<template>
	<div class="box">
        <!-- <input type="file" class="aimg" id="aimg" @change="changeImg()"> -->
        <!-- <div @click="btnBanner()">btnBanner</div> -->
		<ImgCutter
		@cutDown="cutDown" 
		:WatermarkText="WatermarkText"
		:rate="rate"
	>
		<button slot="open">选择图片</button>
	</ImgCutter>
	</div>
</template>

<script>

import api from '../api/index'
import album from '../api/album'
import ImgCutter from 'vue-img-cutter'
export default {
	name: 'index',
	components: {
		ImgCutter
	},

	data() {
		return {
			WatermarkText:"haitundj.com",
            rate:"" , // 裁剪比例
        }
	},

	created() {

	},
	mounted() {
		
	},
	methods: {

	    // changeImg() {
        //     var imgDom = document.getElementById("aimg");
        //     var file = imgDom.files[0];
        //     var data = new FormData();
        //     data.append("images", file);
        //     album.upload(data).then((res) => {
        //         if (res.data.code == 200) {
        //             this.btnBanner(res.data.result.filePath)
        //         }
        //     })
        // },
		cutDown(event) {
            var data = new FormData();
            data.append("images", event.file);
            // this.image = event.dataURL

			
            album.upload(data).then((res) => {
                if (res.data.code == 200) {
                    this.btnBanner(res.data.result.filePath)
                }
            })
        },
	
	
		btnBanner(f){
			var obj ={
				"banner_title": "bg", 
                "banner_url":f, 
                "banner_pos": "MAIN",           
                "banner_type": "INTRANET",   
                "banner_link_id": "1", 
                "banner_link_type": "2", 
                "banner_link": "33" 
			}
			api.addBanners(obj).then((res) => {
				if (res.data.code == 200) {
					console.log(res.data,99999999)
					// this.tabbar = res.data.result
				}
			})
		}


	},
}
</script>

<style scoped>
.box {
	margin-left: 214px;
	margin-top: 60px;
}
h6{
	font-size: 16px;
	margin-top: 16px;
}
@media screen and (max-width: 1340px) {
    .box {
        overflow: hidden;
    }
}

</style>